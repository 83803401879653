//推荐商家接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-15
 */

//推荐商家分页查询
export function recommendStorePageList(parameter){
    return axios({
        url: '/recommendStore/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//推荐商家新增
export function recommendStoreAdd(parameter){
    return axios({
        url: '/recommendStore/add',
        method: 'post',
        data: parameter 
    })
}
//推荐商家编辑
export function recommendStoreEdit(parameter){
    return axios({
        url: '/recommendStore/edit',
        method: 'post',
        data: parameter 
    })
}
//推荐商家详情
export function recommendStoreDetail(parameter){
    return axios({
        url: '/recommendStore/detail',
        method: 'post',
        params: parameter 
    })
}
//推荐商家修改状态
export function recommendStoreDelete(parameter){
    return axios({
        url: '/recommendStore/delete',
        method: 'post',
        data: parameter 
    })
}

